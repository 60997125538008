@import "./colors.scss";

body {    
    .user-bar {
        position: fixed;
        background: map-get($theme-colors,"grey-dark");
        width: 100%;
        padding-bottom: 0;
        z-index: 2;
        .signin-btn,.user-nav {            
            float: right;
            margin-right: 80px;
            font-size: 28px;
            line-height: 28px;            
            color: map-get($theme-colors,"grey-light"); 
            cursor: pointer;
            &.signin-btn {
                margin-top: 15px;
                margin-bottom: 3px;      
            }
            &.user-nav {
                .user-menu a {
                    padding-bottom: 3px;
                    margin-top: 7px;
                    padding-right: 0;
                    color: unset;
                    &:hover {
                        color: map-get($theme-colors,"orange");
                    }
                }
                svg {
                    margin-top: 14px;
                }
            }
        }
    }
    .header {      
        .mr-auto.navbar-nav {
            float: right;
            z-index: 1;
            position: relative;
        }
        .navbar-toggler {
            margin-top: 40px;
            &:hover {
                border: 1px solid map-get($theme-colors,"orange");
            }
        }
        #pi-navbar-nav {
            justify-content: flex-end;            
        }
        padding: 0 50px;
        .navbar-nav {
            margin-top: 40px;
            a:hover {
                color: map-get($theme-colors,"orange");
            }
        }  
        .navbar-brand {
            margin-top: 30px;
        }
        height: 154px;
        background: map-get($theme-colors,"grey");
        border-bottom: 3px solid map-get($theme-colors,"orange");
        z-index: 1;
        font-size: 1.1rem;
        font-weight: 600;
        letter-spacing: .1rem;
    }   
    #page-container {
        position: relative;
        min-height: 100vh;
    }
    #content-wrap {
        padding-bottom: 380px;         
    }
    #footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 380px;            
        padding-top: 60px;
        text-align: center;        
        border-top: 1px solid map-get($theme-colors,"orange"); 
        background: map-get($theme-colors,"grey");
        color: map-get($theme-colors,"grey-light");
        .logo {
            width: 135px;
        }
        .description {
            margin-top: 20px;
        }
        .social-icons {
            font-size: 2.3em;
            margin-top: 20px;
            svg {
                margin: 0 15px;
                &:hover {
                    color: map-get($theme-colors,"orange")
                }
            }
            cursor: pointer;
        }
        .copyright {
            margin-top: 60px;
            padding-top: 30px;
            background: map-get($theme-colors,"grey-dark"); 
            border-top: 1px solid map-get($theme-colors,"orange");
            height: 80px;
        }        
    }     
}