.match-day {
	.current-match {
	    border-color: #f99c00;
	    margin: 20px 10px;
	    min-width: 140px;
	    .card-text {
		    display: grid;
		    grid-template-columns: 100%;
		    grid-template-rows: 50% 50%;
		}
	}
	.teams-banner {
		display: grid;
		grid-template-columns: 1fr 50px 1fr;
		.seperator {			
			font-size: 3em;
			padding: 10px 20px;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.team {
			display: flex;			
			align-items: center;
			justify-content: right;
			height: 100%;
			cursor: pointer;
			&.opponent {
				justify-content: left;
			}
			.team-name,.score {			
				background-color: map-get($theme-colors,"grey");
				border-radius: 10px;
				margin: 10px;
				&.team-name {
					text-align: center;
					font-size: 1.6em;
					padding: 10px 20px;
					width: 100%;
				}
				&.score {
					text-align: center;
					font-size: 2em;					
					padding: 10px 20px;
					line-height: 1.2em;
				}
			}
		}
		@media screen and (max-width: 767px) {
			.seperator {			
				font-size: 2em;		
				padding: 5px 10px;	
			}
			.team {		
				.team-name,.score {		
					margin: 5px;
					&.team-name {
						font-size: 1.2em;
					}
					&.score {						
						font-size: 1.6em;						
					}
				}
			}
		}
		@media screen and (max-width: 576px) {
			.seperator {			
				font-size: 1.2em;		
				padding: 5px 10px;	
			}
			.team {		
				.team-name,.score {		
					margin: 5px;
					&.team-name {
						font-size: 1em;
					}
					&.score {						
						font-size: 1.2em;						
					}
				}
			}
		}
	}
	.points-list {		
		.points-item {
			display: flex;
			justify-content: space-between;
		}
	}
	.players {
		padding: 15px;	
		display: flex;
		justify-content: flex-end;
		height: 100%;
		width: 100%;	
		.list-group-item,  {
			display: grid;
			grid-template-columns: auto 25px 25px;
			gap: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
			border: none;
			border-radius: 10px;
			.icon {				
				&.position {
					&.assigned {
						background: map-get($theme-colors,"secondary");
						border-color: map-get($theme-colors,"secondary");
					}	
				}
				&.remove {
					border-color: map-get($theme-colors,"danger");
					color: map-get($theme-colors,"danger");;
					cursor: pointer;
					&:hover {						
						background:  map-get($theme-colors,"danger");;
						color: white;
					}
					&.disabled {
						cursor: default;
						pointer-events: none;
						border-color: map-get($theme-colors,"grey");
						color: map-get($theme-colors,"grey");
					}
				}		
				&.match-event {
					border-color: map-get($theme-colors,"primary");
					color: map-get($theme-colors,"primary");
					cursor: pointer;
					&:hover {						
						background:  map-get($theme-colors,"primary");
						color: white;
					}
					&.disabled {
						cursor: default;
						pointer-events: none;
						border-color: map-get($theme-colors,"grey");
						color: map-get($theme-colors,"grey");
					}
				}					
			}
			&.hovered {
				background: map-get($theme-colors,"secondary");
			}
			&.selected {
				background: map-get($theme-colors,"primary");
			}
			.pi-select {
				width: 100%;
				min-width: 185px;
				.select__control {
					width: 100%;				
				}	
			}
			&:first-child,&:last-child {
				border-radius: 10px;
			}		
		}
		@media screen and (max-width: 768px) { 				
			justify-content: center;
		}	
	}
	.btn.new-match {
		margin-top: 15px;
		width: 100%;
	}
}

.match-picker {
	width: 300px;
	margin: 50px auto;
}

.divider {
	text-align: center;
}

.create-match-form { 
    
    margin: 50px auto;
    margin-bottom: 100px;
    padding: 30px;
    max-width: 600px;
    .react-datepicker-wrapper{
	    display: block;
	    .pi-datepicker {
	    	width: 100%;
	    }
	}
	.form-label,.btn {
    	margin-top: 20px;
    	&.btn {
    		float: right;
    	}
    }	
    .form-control {    	
    	//max-width: 300px;
    }
}

.nav-pills.pi-pill .nav-item .nav-link {
    color: #f99c00;
    background-color: #201c1c;
    border-color: #f99c00;
    &.active {
	    color: #201c1c;
	    background-color: #f99c00;
	    border-color: #f99c00;   	
    }
}

.spinner-border.pi-spinner {
    color: #f99c00;
    position: absolute;
    top: 50%;
    left: 50%;
}

.pi-select {
	.select__single-value {		
		color: white;					
	}	
	.select__menu {
        background-color: map-get($theme-colors,"grey");
        color: white;
        z-index: 3;
    }
}



.select-holder {
	width: 350px;
	padding: 15px 25px;
	@media screen and (max-width: 768px) { 				
		margin: 0 auto;
	}	
}

.match-timer {		
	display: flex;
	justify-content: flex-end;
	align-items: center;			
	.card {
		width: 350px;
		.card-body {
			.timers {
				display: flex;
				justify-content: space-around;
				.timer {
					display: flex;
					justify-content: center;
					align-items: center;
					.time {
						&.overtime {
							color: red;
						}	
					}
				}				
			}			
			.status {
				text-align: center;
			}	
		}
		.card-footer {
			display: flex;
			justify-content: space-around;
			background-color: unset;
		}
	}
}

.match-report {
	margin: 50px 0;
	display: flex;
	justify-content: center;
	.match-event {		
		border-color: map-get($theme-colors,"grey");
		.time {
			color: map-get($theme-colors,"primary");	
		}
		.text {
			color: map-get($theme-colors,"grey-light");
			&.bad {
				color: red;
			}
			&.good {
				color: green;
			}
		}
		.edit-icon {
			color: map-get($theme-colors,"primary");
			position: absolute;
			right: 10px;
			top: 10px;
			cursor: pointer;			
		}
	}	
}