@import "~bootstrap/scss/functions";
@import "./layout.scss";
@import "./colors.scss";
@import "./home.scss";
@import "./modal.scss";
@import "./matchDay.scss";
@import "./formationPicker.scss";
@import "./teams.scss";
@import "./register.scss";
@import "./form.scss";
@import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';



body {        
    font-family: 'Montserrat', sans-serif !important;    
    &.light-mode {   
        background-color: #EEEEEE;
        color: black;
        .backdrop {
            background-color: #E8E8E8;
        }
    }    
    .border-primary {
        border-top: .2rem solid map-get($theme-colors,"primary") !important ;
    }
    .row .col.no-padding{
        padding: 0;
    }
    .text-dark {
        color: map-get($theme-colors,"grey-dark");
    }
    .jumbo {
        color: map-get($theme-colors,"grey-light");
        background-color: map-get($theme-colors,"grey-dark");
        border: .1rem solid map-get($theme-colors,"primary");
        font-size: 1.2rem;
        text-shadow: .1rem .1rem .2rem rgba(0, 0, 0, 1);
    }    
    .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 1px solid white;
        height: 30px;
        width: 42px;
        text-align: center;
        font-size: 12px;
    }
}

@import "~bootstrap/scss/bootstrap";