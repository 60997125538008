form {
    .form-control {
        background-color: map-get($theme-colors,"grey-dark");
        &:focus {
            background-color: map-get($theme-colors,"grey");
        }
    }    
    .form-label {
        margin-top: 20px;
    }     
}