@import "./colors.scss";


.home {
    background: white;
    .carousel {
        .carousel-item {
            max-height: 600px;
            img {
                width: 100%;
            }
            .carousel-caption {
                position: absolute;
                top: 54%;
                transform: translateY(-50%);
                text-transform: uppercase;
            }
            .bg-custom {
                background-color: rgba(0, 0, 0, .4);
                color: white;
                border-radius: .1rem;
            }
            .carousel-caption h1 {
                font-size: 3.8rem;
                text-shadow: .1rem .1rem .3rem rgba(0, 0, 0, 1);
            }            
            .carousel-caption h3 {
                font-size: 2.2rem;
                text-shadow: .1rem .1rem .2rem rgba(0, 0, 0, 1);
            }
            .btn {
                margin: 0 5px;
            }
        }
    }    
    .collapse img {
        width: 100%;
    }
    .collapse-toggle {
        width: 100%;
        background-image: linear-gradient(to right, #ffffff, #005e49, #ffffff );
        color: map-get($theme-colors,"primary");
        text-align: center;
        font-size: 2.5em;
    }    
    .fixed-background {
        position: relative;
        width: 100%;
        z-index: 1000 !important;
        padding: 10rem 0;    
        .fixed-wrap {
            clip: rect(0, auto, auto, 0);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -999 !important;
            .fixed {
                z-index: -999 !important;
                display: block;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-size: cover;
                background-position: center center;
                -webkit-transform: translateZ(0);
                transform: translateZ(0);
                will-change: transform;
                background-image: url('../assets/img/matrix.png');
                position: fixed;
            }
        }
        .btn {
            margin: 0 5px;
        }
    }        
} 