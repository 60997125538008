.teams {
	margin: 30px 0;
	.add-btn, .edit-btn {
		float: right;
		margin-top: 15px;
		&.edit-btn {
			margin-right: 10px;
		}
	}
	.empty-list {
		border: 1px solid map-get($theme-colors,"grey");
		text-align: center;
		padding: 30px;
		color: map-get($theme-colors,"grey-light");
		border-radius: 10px;
	}
	.players {
		.list-group-item {
			background-color: map-get($theme-colors,"grey");
			color: map-get($theme-colors,"grey-light");
			border-radius: 4px;	
			border: 1px solid map-get($theme-colors,"grey-light");
			margin: 5px;
			display: flex;
			justify-content: space-between;
			cursor: pointer;
		}	
	}
	.kits {
		display: flex;
		flex-direction: row;
		overflow: auto;
		gap: 15px;
		justify-content: center;
		.kit {
			width: 150px;
			cursor: pointer;
			flex-shrink: 0;
		}

	}
}