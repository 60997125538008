.formation-picker-tab {
	margin: 60px 0;
}

.formation-picker {		
	.row {
		.col {
			padding: 0;
		}
		@media screen and (max-width: 767px) {
			flex-direction: column-reverse;
		}
	}	
	.pitch {			
		width: 350px;	
		height: 540px;	
		background-color: green;
		position: relative;
		margin: auto;
		@media screen and (min-width: 768px) { 
			margin: 0;
		}
		img {
			position: absolute;
			width: 100%;
			height: 100%;
		}
		.player {		
			position: absolute;				
			cursor: pointer;
			border-radius: 50%;
			z-index: 1;
			svg {
				width: 100%;
			}
			.jersey-label {
				font-weight: bolder;	
				text-align: center;
				font-size: 12px;
				position: relative;
				top: -34px;					
				font-family: Arial, sans-serif;
			}			
			&:hover, {					
				&:not(:active) {
					background-color: map-get($theme-colors,"primary");
				}			
			}	
			&.hovered {
				background-color: map-get($theme-colors,"primary");
			}
			&:active {
				z-index: 2;
				opacity: 0.4;
			}
			&.unassigned {
				&:not(.selected) {
					z-index: 2;
					opacity: 0.4;
				}
			}
			&.collision {
				background-color: red;				
			}		
			&.selected {					
				&:not(:active) {
					background-color: orange;
				}			
			}						
		}
	}
}