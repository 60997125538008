.modal {
	.modal-dialog .modal-content {
		background-color: map-get($theme-colors,"grey");		
		a {	
			margin: 5px 0;		
			color: white;
		}
		.modal-body {
			padding: 30px;			
			
		}
	}	
	&.match-event-modal {
		.event-time {
				height: 100%;
				display: flex;
				justify-content: center;
				flex-direction: column;
				align-items: center;
				font-size: 1.6em;			
			}
		}
	&.edit-kit-modal {				
		.preview-col {
			display: flex;
			justify-content: center;
			.preview {
				width: 300px;
				display: flex;
				justify-content: center;
				.jersey-label {
					font-weight: bolder;	
					text-align: center;
					font-size: 60px;
					position: absolute;
					top: 130px;					
					font-family: Arial, sans-serif;
				}
			}
		}	
		.jersey-option {
			width: 30px;
		}	
	}
	&.match-result-modal {
		.modal-dialog .modal-content .modal-body {
			padding: 0 30px;
		}
		.row {
			margin: 15px 0;
			&.score-row {
				text-align: center;
				font-size: 20px;
				font-weight: bolder;
			}
		}
		.match-form {
			padding: 0;
		}
		.match-events {		
				background-color: #222222;
				border-color: hsl(0, 0%, 80%);
				border-radius: 4px;
				border-style: solid;
				border-width: 1px;
			.me-filters {
				width: 100%;
				padding: 5px;
				background-color: #222222;
			}
			.list-group {				
				height: 150px;
				background-color: #222222;
				overflow-y: scroll;
				padding: 5px;
				.match-event {
					padding: 5px 10px;
					display: flex;
					justify-content: space-between;
					.btn {
						padding: 1px 4px 2px 4px;
						margin-left: 5px;
					}					
				}
				.empty-list {
					display: flex;
					justify-content: center;
					align-items: center;
					height: 100%;
					color: map-get($theme-colors,"grey-light");
				}
			}
		}
		.add-me-btn {
			right: 42px;
			margin-top: 7px;
			position: absolute;
		}	
		.btn-holder {
			width: 100%;
			display: flex;
			justify-content: flex-end;
			gap: 7px;
		}
		.collapse.show {
			.form-label {
				margin: 0;
			}
			.row {
				margin: 5px 0;
			}	
		}
	}
}